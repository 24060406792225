import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import  en  from './i18n.en';
import  fr  from './i18n.fr';

// I18n internationalization options
const options = {
    interpolation: {
        escapeValue: false, // not needed for react!!
    },

    debug: true,

    // lng: 'en',

    resources: {
        fr: {
            common: fr['fr-FR'],
        },
        en: {
            common: en.en,
        },
    },

    fallbackLng: 'fr',

    ns: ['common'],

    defaultNS: 'common',

    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default'
    },
};

i18n
    .use(LanguageDetector)
    .init(options)
    /*.changeLanguage('en')
    .then((result) => {

    })
    .catch((err) => {
        if (err) return console.log('something went wrong loading', err);
    })*/;

export default i18n;
