const axios = require('axios');

class APIService{
    /**
     * Fetch own informations
     */
    static me(){
        return axios.get('/api/me/');
    }

    static findImmos(points, start, offset, locality) {
        // Define data's
        let data = { points, start, offset };

        // Send post request
        return axios.post(`/api/find/${locality}`, data)
            .then((result => result));
    }

    static countImmos(points, locality) {
        // Define data's
        let data = { points };

        // Send post request
        return axios.post(`/api/count/` + locality, data)
            .then((result => result.count));
    }

    static findImmosHistory(startDate, endDate) {
        // Define data's
        let data = {
            startDate,
            endDate,
        };

        // Send post request
        return axios.post(`/api/history`, data)
            .then((result => result.history));
    }

    static setImmoRelevancy(immo, isRelevant) {
        // Define data's
        let data = { isRelevant, immoId: immo._id };

        // Send post request
        return axios.post(`/api/relevancy`, data);
    }

    static setImmoComment(immo, comment) {
        // Define data's
        let data = { comment, immoId: immo._id };

        // Send post request
        return axios.post(`/api/comment`, data);
    }

    static setImmoContactRelevancy(immo, relevancyIndex, contactIndex) {
        // Define data's
        let data = { relevancyIndex, contactId: immo.potentialContacts[contactIndex]._id, immoId: immo._id };

        // Send post request
        return axios.post(`/api/contact/relevancy`, data);
    }

    static setTimestampName(timestamp, name) {
        // Define data's
        let data = { name, timestampId: timestamp._id };

        // Send post request
        return axios.post(`/api/timestamp/name`, data);
    }
}

export default APIService;
