// src/js/reducers/index.js
import {
    INIT_APP_OK,
    ERROR,
    CLEAR_MAP,
    SIGN_IN_OK,
    SIGN_IN_KO,
    SHOW_INFO_WINDOW,
    HIDE_INFO_WINDOW,
    SIGN_OUT_OK,
    UPDATE_MY_LOCATION,
    ADD_SEARCH_POINT,
    IMMOS_COUNT_OK,
    IMMOS_COUNT,
    IMMOS_GET,
    IMMOS_HISTORY_GET,
    IMMOS_HISTORY_GET_KO,
    IMMOS_HISTORY_GET_OK,
    IMMOS_COUNT_KO,
    IMMOS_GET_KO,
    IMMOS_GET_OK,
    IMMOS_RELEVANCY_OK,
    IMMOS_CONTACT_RELEVANCY_OK,
    SIGN_IN,
    ADDR_CHANGE,
    ADDR_CHANGE_OK,
    ADDR_CHANGE_KO,
    IMMOS_COMMENT_SET,
    IMMOS_COMMENT_OK,
    IMMOS_COMMENT_KO,
    TIMESTAMP_NAME_SET,
    TIMESTAMP_NAME_OK,
    TIMESTAMP_NAME_KO,
    CHECK_USER_REGISTRATION_ACTIVATED
} from "../constants/action-types";
import {INITIAL_DIST_LAT, INITIAL_DIST_LNG, VD_LOCALITY} from '../../utils/constants'

const noError = {
    error: false,
    errorText: '',
    loading: false,
    loadingProgress: false,
};

const initialState = {
    init: false,
    me: {
        location: {
            lat: 46.796571,
            lng: 7.155104,
            locality: ''
        },
        email: '',
    },
    isUserRegistrationActivated: false,
    address: 'Fribourg',
    polygonPoints: [],
    radius: 100,
    showingInfoWindow: false,
    activeMarker: null,
    immosCount: -1,
    immosHistory: [],
    requestsAvailable: -1,
    ...noError
};

function getPolygonCoord(initialLat, initialLng) {
    const initialPolygon = [];
    initialPolygon.push({lat: initialLat + INITIAL_DIST_LAT, lng: initialLng - INITIAL_DIST_LNG});
    initialPolygon.push({lat: initialLat + INITIAL_DIST_LAT, lng: initialLng + INITIAL_DIST_LNG});
    initialPolygon.push({lat: initialLat - INITIAL_DIST_LAT, lng: initialLng + INITIAL_DIST_LNG});
    initialPolygon.push({lat: initialLat - INITIAL_DIST_LAT, lng: initialLng - INITIAL_DIST_LNG});
    return initialPolygon;
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case INIT_APP_OK:
            const initialLat = state.me.location.lat;
            const initialLng = state.me.location.lng;
            const polygon = getPolygonCoord(initialLat, initialLng);

            return {
                ...state,
                ...noError,
                init: true,
                requestsAvailable: action.payload.requestsAvailable,
                polygonPoints: [...polygon],
                me: {
                    ...state.me,
                    email: action.payload
                }
            };
        case CHECK_USER_REGISTRATION_ACTIVATED:
            return {
                ...state,
                isUserRegistrationActivated: action.payload,
            };
        case SIGN_IN_OK:
            return {
                ...state,
                ...noError,
            };
        case SIGN_IN_KO:
            return {
                ...state,
                ...noError,
                error: true,
                errorText: action.payload.errorText
            };
        case SIGN_OUT_OK:
            return {
                ...initialState
            };
        case IMMOS_GET:
            return {
                ...state,
                loadingProgress: true
            };
        case SIGN_IN:
        case IMMOS_COUNT:
        case IMMOS_HISTORY_GET:
        case ADDR_CHANGE:
        case IMMOS_COMMENT_SET:
        case TIMESTAMP_NAME_SET:
            return {
                ...state,
                loading: true
            };
        case ADDR_CHANGE_OK:
            return {
                ...state,
                ...noError,
                address: action.payload
            };
        case CLEAR_MAP:
            return {
                ...state,
                messages: [],
                peoples: [],
                inactivePeoples: [],
            };
        case SHOW_INFO_WINDOW:
            return {
                ...state,
                showingInfoWindow: true,
                activeMarker: action.payload
            };
        case HIDE_INFO_WINDOW:
            return {
                ...state,
                showingInfoWindow: false,
                activeMarker: null
            };
        case UPDATE_MY_LOCATION:
            const location = action.payload;
            const newPolygon = getPolygonCoord(location.lat, location.lng);
            return {
                ...state,
                ...noError,
                immosCount: -1,
                polygonPoints: [...newPolygon],
                me: {
                    ...state.me,
                    location: location
                }
            };
        case ADD_SEARCH_POINT:
            const {edge, vertex, lat, lng} = action.payload;
            const {locality} = state.me.location;

            const newCoord = {lat: parseFloat(lat.toFixed(6)), lng: parseFloat(lng.toFixed(6))};
            let newPoints = state.polygonPoints;
            const isPolygonRestricted =  VD_LOCALITY.indexOf(locality.toLowerCase()) !== -1;

            if (!vertex && vertex !== 0) {
                if(!isPolygonRestricted){
                    const baseLength = newPoints.length;
                    const firstSlice = newPoints.slice(0, edge + 1);
                    const secondSlice = newPoints.slice(edge + 1, baseLength);
                    newPoints = [...firstSlice, newCoord, ...secondSlice];
                }else{
                    // TODO display toast
                    newPoints = [...newPoints];// trigger data change
                }

            } else {
                if(!isPolygonRestricted){
                    newPoints[vertex] = newCoord;
                    newPoints = [...newPoints];// trigger data change
                }else{
                    const leftPoint = newPoints[(vertex + 3) % 4];
                    const latLeft = leftPoint.lat;// -1 -> to the left
                    const lngLeft = leftPoint.lng;// -1 -> to the left
                    const rightPoint = newPoints[(vertex + 1) % 4];
                    const lngRight = rightPoint.lng;// +1 -> to the right
                    const latRight = rightPoint.lat;// +1 -> to the right
                    const { lat, lng } = newCoord;
                    const diffLatLeft = lat - latLeft;
                    const diffLngRight = lng - lngRight;
                    const diffLngLeft = lng - lngLeft;
                    const diffLatRight = lat - latRight;

                    newPoints[vertex] = newCoord;

                    if(vertex % 2){
                        newPoints[(vertex + 3) % 4] = {
                            ...leftPoint,
                            lat: latLeft + diffLatLeft,
                        };

                        newPoints[(vertex + 1) % 4] = {
                            ...rightPoint,
                            lng: lngRight + diffLngRight,
                        };
                    }else{
                        newPoints[(vertex + 3) % 4] = {
                            ...leftPoint,
                            lng: lngLeft + diffLngLeft,
                        };

                        newPoints[(vertex + 1) % 4] = {
                            ...rightPoint,
                            lat: latRight + diffLatRight,
                        };
                    }
                    newPoints = [...newPoints];// trigger data change
                }
            }

            return {
                ...state,
                ...noError,
                immosCount: -1,
                polygonPoints: newPoints
            };
        case IMMOS_COUNT_OK:
            return {
                ...state,
                ...noError,
                immosCount: action.payload,
            };
        case IMMOS_GET_OK:
            return {
                ...state,
                ...noError,
            };
        case IMMOS_HISTORY_GET_OK:
            return {
                ...state,
                ...noError,
                immosHistory: action.payload,
            };
        case IMMOS_RELEVANCY_OK:
        case IMMOS_CONTACT_RELEVANCY_OK:
        case IMMOS_COMMENT_OK:
            const updatedImmo = action.payload;
            const {immosHistory} = state;
            for (let j = 0; j < immosHistory.length; j++) {
                const timestamp = immosHistory[j];
                for (let i = 0; i < timestamp.immos.length; i++) {
                    const immo = timestamp.immos[i];
                    if (immo.EGRID === updatedImmo.EGRID) {
                        timestamp.immos[i] = {
                            ...updatedImmo
                        };
                    }
                }
                immosHistory[j] = {
                    ...timestamp
                }
            }
            return {
                ...state,
                immosHistory: [...immosHistory],
                ...noError,
            };
        case TIMESTAMP_NAME_OK:
            return {
                ...state,
                ...noError,
            };
        case IMMOS_HISTORY_GET_KO:
        case IMMOS_COUNT_KO:
        case IMMOS_GET_KO:
        case ADDR_CHANGE_KO:
        case IMMOS_COMMENT_KO:
        case TIMESTAMP_NAME_KO:
            return {
                ...state,
                ...noError,
                error: true,
            };
        case ERROR:
            return {
                ...state,
                ...noError,
                error: true,
                errorText: action.payload.errorText
            };
        default:
            return state;
    }
}

export default rootReducer;
