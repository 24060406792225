import {Component} from "react";
import './Collapse.scss';
import React from "react";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import DateService from "../../utils/dateUtils";
import TableComponent from "../table/table";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleDown, faFileExport, faPen} from "@fortawesome/free-solid-svg-icons";
import {setTimestampName} from "../../redux/actions";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import {withTranslation} from "react-i18next";
import ReactExport from "react-data-export";
import {RELEVANCY_INDEX} from '../../utils/constants';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class Collapse extends Component {

    constructor(props) {
        super(props);
        this.exportRef = [];

        this.editTimestampName = this.editTimestampName.bind(this);
    }

    setExportRef = (element, index) => {
        this.exportRef[index] = element;
    };

    editTimestampName(event, timestamp) {
        event.preventDefault();
        this.props.setTimestampName(timestamp, 'coucou');
    }

    exportTimestamp(event, index) {
        event.stopPropagation();
        const excelFile = this.exportRef[index];
        excelFile.download();
    }

    getExportFilename(timestamp){
        return DateService.formatTimestamp(timestamp.timestamp).replace(/ /g, '_')
    }

    formatLocality(locality){
        if(!locality){
            return ' - '
        }else if(locality.length === 2) {
            return locality.toUpperCase();
        }

        let formatedLocality = locality;
        return formatedLocality[0].toUpperCase() + (formatedLocality.substr(1, formatedLocality.length - 1));
    }

    formatDataForExport(immos) {
        const columns = [
            {
                title: "No immeuble",
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            }, {
                title: "No plan",
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            }, {
                title: "Rue",
                width: {wpx: 400},
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            }, {
                title: "Surface parcelle",
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            }, {
                title: "Propriétaire selon RF",
                width: {wpx: 250},
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            }, {
                title: "Téléphone",
                width: {wpx: 250},
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            }, {
                title: "Date",
                width: {wpx: 250},
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            },  {
                title: "Commentaires",
                width: {wpx: 250},
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            },{
                title: "Contacts potentiels",
                width: {wpx: 250},
                style: {
                    font: {bold: true, sz: '16'},
                    fill: {patternType: "solid", fgColor: {rgb: "bdc3c7"}}
                }
            },
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
            {title: "", width: {wpx: 250},},
        ];

        const data = immos
            .filter(immo => !immo.hidden)
            .map((immo) => {
            const {building} = immo;
            const noPlan = building.noPlan.join(',');
            const streets = building.street.join(',');
            const owners = immo.owners.join(',');
            const potentialContacts = immo.potentialContacts.map((potentialContact) => {
                let bgColor = 'FFFFFFFF';
                let pattern = 'none';
                switch (potentialContact.relevancyIndex) {
                    case RELEVANCY_INDEX.GREEN:
                        bgColor = '2ecc71';
                        pattern = 'solid';
                        break;
                    case RELEVANCY_INDEX.ORANGE:
                        bgColor = 'f39c12';
                        pattern = 'solid';
                        break;
                    case RELEVANCY_INDEX.RED:
                        bgColor = 'e74c3c';
                        pattern = 'solid';
                        break
                }
                return {
                    value: potentialContact.data ? potentialContact.data.join('\r\n') : '-',
                    style: {
                        alignment: {wrapText: true},
                        fill: {patternType: pattern, fgColor: {rgb: bgColor}}
                    }
                };
            });

            return [
                {
                    value: building.noBuilding,
                    style: {
                        font: {bold: true},
                        alignment: {vertical: 'center', horizontal: 'center', wrapText: true}
                    }
                }, {
                    value: noPlan,
                    style: {
                        alignment: {vertical: 'center', horizontal: 'center', wrapText: true}
                    }
                }, {
                    value: streets,
                    style: {
                        alignment: {vertical: 'center', wrapText: true}
                    }
                }, {
                    value: building.m2 ? building.m2 + 'm2' : '-',
                    style: {
                        alignment: {vertical: 'center', horizontal: 'center', wrapText: true}
                    }
                }, {
                    value: owners,
                    style: {
                        alignment: {vertical: 'center', wrapText: true}
                    }
                }, {
                    value: '',
                    style: {
                        alignment: {vertical: 'center', wrapText: true}
                    }
                }, {
                    value: '',
                    style: {
                        alignment: {vertical: 'center', wrapText: true}
                    }
                }, {
                    value: '',
                    style: {
                        alignment: {vertical: 'center', wrapText: true}
                    }
                },
                ...potentialContacts,
            ];
        });
        return [
            {
                columns,
                data,
            },
        ];
    }

    render() {
        const {showNotRelevant, immosHistory, t} = this.props;

        return (
            immosHistory && immosHistory.length > 0 ?
                immosHistory.map((item, index) => {
                    return <ExpansionPanel className='collapse' key={'collapse-item-' + index}>
                        <ExpansionPanelSummary className='panel-summary'>
                            <div className='name-box'>
                                <span>{ DateService.formatTimestamp(item.timestamp) }</span>
                                <span>({ this.formatLocality(item.locality) })</span>
                                {/*<IconButton onClick={(event) => this.editTimestampName(event, item)}>
                                    <FontAwesomeIcon size={'1x'} className="icon" icon={ faPen }/>
                                </IconButton>*/}

                            </div>
                            <div className='collapse-box'>
                                <ExcelFile ref={ref => this.setExportRef(ref, index)} filename={this.getExportFilename(item)} element={
                                    <Tooltip title={t('infos.export')}>
                                        <IconButton onClick={(event) => this.exportTimestamp(event, index)}>
                                            <FontAwesomeIcon size={'1x'} className="icon" icon={faFileExport}/>
                                        </IconButton>
                                    </Tooltip>
                                }>
                                    <ExcelSheet dataSet={this.formatDataForExport(item.immos)} name="Organization"/>
                                </ExcelFile>
                                <FontAwesomeIcon size={'1x'} className="icon icon-collapse" icon={faAngleDown}/>
                            </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className='panel-details'>
                            <TableComponent data={[...item.immos]} showNotRelevant={showNotRelevant}/>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                })
                :
                <div className='no-content'>
                    <span>Aucune donnée</span>
                </div>
        );
    }
}

Collapse.defaultProps = {
    showNotRelevant: false,
};

const mapStateToProps = state => {
    return {
        immosHistory: state.app.immosHistory,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setTimestampName: (name, timestamp) => dispatch(setTimestampName(name, timestamp))
    };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(Collapse)));
