import {Component} from "react";
import './home.scss';
import React from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import MapsContainer from "../../components/map/map";
import TextField from '@material-ui/core/TextField';
import {initApp, clearMap, updateMyLocation, findImmos, countImmos, searchAddr} from "../../redux/actions";
import Button from "../../components/button/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMapMarkerAlt, faRedo, faInfoCircle, faCloudDownloadAlt, faSync} from "@fortawesome/free-solid-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';
import Card from "../../components/card/Card";
import IconButton from '@material-ui/core/IconButton';
import {TIME_SLEEP_REQUESTS_S} from "../../utils/constants";
import DateUtils from '../../utils/dateUtils';
import LinearProgress from '@material-ui/core/LinearProgress';

const {withTranslation} = require('react-i18next');

// home screen
class HomeScreen extends Component {
    constructor(props) {
        super(props);

        // initial state
        this.state = {
            nbrRequests: 1,
            address: null,
            progressValue: -1,
            currentProgressTimeInSec: -1,
            totalProgressTimeInSec: -1,
        };
        this.getTimeToRest = this.getTimeToRest.bind(this);
        this.startRestTime = this.startRestTime.bind(this);
        this.finishRestTime = this.finishRestTime.bind(this);
        this.findImmos = this.findImmos.bind(this);
        this.countImmos = this.countImmos.bind(this);
        this.searchAddr = this.searchAddr.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    /**
     * Fired when component did mount
     */
    componentDidMount() {
        if(!this.props.init){
            this.props.initApp();
            this.props.searchAddr(this.state.address);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
        const {nbrRequests, totalProgressTimeInSec} = this.state;

        // Update inital time if nbrRequests change
        const newTotalProgressTimeInSec = (nbrRequests - 1) * TIME_SLEEP_REQUESTS_S;
        if(nbrRequests > 0 && totalProgressTimeInSec !== newTotalProgressTimeInSec) {
            this.setState({totalProgressTimeInSec: newTotalProgressTimeInSec, currentProgressTimeInSec: newTotalProgressTimeInSec});
        }

        // Start interval to update clock when fetch data
        if(!prevProps.loadingProgress && this.props.loadingProgress){
            this.startRestTime();
        }else if(!this.props.loadingProgress){
            this.finishRestTime();
        }
    }

    static getDerivedStateFromProps(props, state){
        let isStateAddr = false;

        if(state.address !== null)
            isStateAddr = state.address.length >= 0;

        const addr = isStateAddr ? state.address : props.address;

        return {
            ...state,
            address: addr
        }
    }

    getTimeToRest(timeToRest){
        if(timeToRest <= 0){
            return '00:00';
        }
        return DateUtils.timeFromSec(timeToRest);
    }

    startRestTime(){
        let {currentProgressTimeInSec, totalProgressTimeInSec} = this.state;
        this.progressInterval = setInterval(() => {
            if(currentProgressTimeInSec >= 0){
                const progressValue = DateUtils.getRangeFromTime(currentProgressTimeInSec, totalProgressTimeInSec);
                currentProgressTimeInSec = currentProgressTimeInSec - 1;
                this.setState({
                    progressValue,
                    currentProgressTimeInSec
                });
            }else{
                clearInterval(this.progressInterval);
            }
        }, 1000)
    }

    finishRestTime() {
        clearInterval(this.progressInterval);
    }

    /**
     * Handle input update
     * @param event
     */
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleKeyPress(target) {
        if(target.charCode === 13){
            this.searchAddr();
        }
    }

    searchAddr(){
        this.props.searchAddr(this.state.address)
    }

    findImmos(){
        const { polygonPoints } = this.props;
        const {nbrRequests} = this.state;

        if(nbrRequests && nbrRequests > 0){
            const start = 0;
            const offset = nbrRequests;
            this.props.findImmos(polygonPoints, start, offset);
        }else{
            // TDO display toast
        }
    }

    countImmos(){
        const { polygonPoints, currentLocation } = this.props;
        this.props.countImmos(polygonPoints, currentLocation.locality);
    }

    render() {
        const {t, immosCount, loading, loadingProgress} = this.props;
        const {nbrRequests, address, progressValue, currentProgressTimeInSec} = this.state;

        return (
            <div className="home-page">
                <div className="content-wrapper">
                    <div className="maps">
                        <MapsContainer/>
                    </div>
                    <div className={'cmd-wrapper'}>
                        <Card className="cmd-inner">
                            <h3>{t('commands.position')}</h3>
                            <TextField
                                id="address"
                                name="address"
                                label={t('app.address')}
                                type="search"
                                margin="normal"
                                value={address} onChange={event => this.handleChange(event)}
                                onKeyPress={this.handleKeyPress}
                            />
                            <div className={'btn-wrapper'}>
                                <Button text={t('commands.move-txt')} icon={faMapMarkerAlt} onClick={this.searchAddr}/>
                            </div>

                            <h3>{t('commands.title')}</h3>
                            <div className='btn-wrapper'>
                                <div>
                                    <span><b>{immosCount >= 0 ? immosCount : '-'}</b> {t('app.potential-immos')}</span>
                                    <Tooltip title={t('infos.potential-immos')}>
                                        <IconButton>
                                            <FontAwesomeIcon size={'1x'} className="icon" icon={faInfoCircle} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Button text={t('commands.search-txt')} icon={faRedo} onClick={this.countImmos}/>
                                <div className='row'>
                                    <Button disabled={immosCount < 0} text={t('commands.get-txt')} icon={faCloudDownloadAlt} onClick={this.findImmos}/>
                                    <Tooltip title={t('infos.nbr-requests-immos')}>
                                        <IconButton>
                                            <FontAwesomeIcon size={'1x'} className="icon" icon={faInfoCircle} />
                                        </IconButton>
                                    </Tooltip>
                                    <TextField
                                        disabled={immosCount < 0}
                                        id="standard-number"
                                        label="Nbr requêtes"
                                        type="number"
                                        defaultValue={nbrRequests}
                                        onChange={(event) => this.setState({nbrRequests: event.target.value})}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                                <div className='box'>
                                    <span>{t('app.fetch-time-immos')}<b>{nbrRequests > 0 ? this.getTimeToRest((nbrRequests - 1) * TIME_SLEEP_REQUESTS_S) : '-'}</b></span>
                                    <Tooltip title={t('infos.fetch-time-immos')}>
                                        <IconButton>
                                            <FontAwesomeIcon size={'1x'} className="icon" icon={faInfoCircle} />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {
                    loading && <div className={'loading-mask'}>
                        <FontAwesomeIcon size={'3x'} className="icon" icon={ faSync } spin/>
                    </div>
                }
                {
                    loadingProgress && <div className='loading-mask-outter'>
                        <div className='loading-mask'/>
                        <Card className='card-progress'>
                            <h3>{t('app.loading-progress')}</h3>
                            <span>{t('app.rest-time') + this.getTimeToRest(currentProgressTimeInSec)}</span>
                            <LinearProgress className='progress' variant="determinate" value={progressValue} />
                        </Card>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        address: state.app.address,
        currentLocation: state.app.me.location,
        immosCount: state.app.immosCount,
        polygonPoints: state.app.polygonPoints,
        init: state.app.init,
        loading: state.app.loading,
        loadingProgress: state.app.loadingProgress,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        initApp: () => dispatch(initApp()),
        updateMyLocation: (lat, lng) => dispatch(updateMyLocation(lat, lng)),
        findImmos: (points, start, offset) => dispatch(findImmos(points, start, offset)),
        countImmos: (points, locality) => dispatch(countImmos(points, locality)),
        searchAddr: (addr) => dispatch(searchAddr(addr)),
        clearMap: () => dispatch(clearMap()),
    };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeScreen)));
