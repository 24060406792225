export const INIT_APP_OK = "INIT_APP_OK";
export const CHECK_USER_REGISTRATION_ACTIVATED = "CHECK_USER_REGISTRATION_ACTIVATED";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_OK = "SIGN_IN_OK";
export const SIGN_OUT_OK = "SIGN_OUT_OK";
export const SIGN_IN_KO = "SIGN_IN_KO";
export const CLEAR_MAP = "CLEAR_MAP";
export const SHOW_INFO_WINDOW = "SHOW_INFO_WINDOW";
export const HIDE_INFO_WINDOW = "HIDE_INFO_WINDOW";
export const UPDATE_MY_LOCATION = "UPDATE_MY_LOCATION";
export const ADD_SEARCH_POINT = "ADD_SEARCH_POINT";
export const IMMOS_HISTORY_GET = "IMMOS_HISTORY_GET";
export const IMMOS_HISTORY_GET_OK = "IMMOS_HISTORY_GET_OK";
export const IMMOS_HISTORY_GET_KO = "IMMOS_HISTORY_GET_KO";
export const IMMOS_GET = "IMMOS_GET";
export const IMMOS_GET_OK = "IMMOS_GET_OK";
export const IMMOS_GET_KO = "IMMOS_GET_KO";
export const IMMOS_COUNT = "IMMOS_COUNT";
export const IMMOS_COUNT_OK = "IMMOS_COUNT_OK";
export const IMMOS_COUNT_KO = "IMMOS_COUNT_KO";
export const IMMOS_RELEVANCY_SET = "IMMOS_RELEVANCY_SET";
export const IMMOS_RELEVANCY_OK = "IMMOS_RELEVANCY_OK";
export const IMMOS_RELEVANCY_KO = "IMMOS_RELEVANCY_KO";
export const IMMOS_CONTACT_RELEVANCY_SET = "IMMOS_CONTACT_RELEVANCY_SET";
export const IMMOS_CONTACT_RELEVANCY_OK = "IMMOS_CONTACT_RELEVANCY_OK";
export const IMMOS_CONTACT_RELEVANCY_KO = "IMMOS_CONTACT_RELEVANCY_KO";
export const ADDR_CHANGE = "ADDR_CHANGE";
export const ADDR_CHANGE_OK = "ADDR_CHANGE_OK";
export const ADDR_CHANGE_KO = "ADDR_CHANGE_KO";

export const IMMOS_COMMENT_SET = "IMMOS_COMMENT_SET";
export const IMMOS_COMMENT_OK = "IMMOS_COMMENT_OK";
export const IMMOS_COMMENT_KO = "IMMOS_COMMENT_KO";

export const TIMESTAMP_NAME_SET = "TIMESTAMP_NAME_SET";
export const TIMESTAMP_NAME_OK = "TIMESTAMP_NAME_OK";
export const TIMESTAMP_NAME_KO = "TIMESTAMP_NAME_KO";

export const ERROR = "ERROR";
