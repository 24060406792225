import React from 'react';
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";

class ModalComponent extends React.Component{

    render(){
        const { open, body, okTxt, cancelTxt } = this.props;
        return (
            <div className='modal' style={{ position: "absolute" }}>
                <Modal
                    open={open}
                    onClose={this.props.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description">
                    <div className='modal-body'>
                        <div className='body'>{body}</div>
                        <div className='footer'>
                            {
                                cancelTxt && <Button variant="contained" onClick={this.props.cancel}>{ cancelTxt }</Button>
                            }
                            <Button variant="contained" color='primary' onClick={this.props.accept}>{ okTxt }</Button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ModalComponent.defaultProps = {
    okTxt: 'Ok',
    cancelTxt: null
};

export default ModalComponent;
