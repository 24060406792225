import {Component} from "react";
import './history.scss';
import React from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {findImmosHistory} from "../../redux/actions";
import Collapse from "../../components/collapse/Collapse";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSync} from "@fortawesome/free-solid-svg-icons";
import DateFnsUtils from '@date-io/date-fns';
import { subDays } from 'date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
const { withTranslation } = require('react-i18next');

// home screen
class HistoryScreen extends Component {
    constructor(props){
        super(props);
        let now = new Date();
        now.setHours(23);
        now.setMinutes(59);
        now.setSeconds(59);
        let startDate = subDays(now, 1);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);

        this.state = {
            showNotRelevant: false,
            selectedStartDate: startDate,
            selectedEndDate: now,
        };
        props.findImmosHistory(startDate, now);

        this.handleDateChangeStart = this.handleDateChangeStart.bind(this);
        this.handleDateChangeEnd = this.handleDateChangeEnd.bind(this);
        this.updateData = this.updateData.bind(this);
    }

    updateData(startDate, endDate){
        this.props.findImmosHistory(startDate, endDate);
    }
    handleDateChangeStart(date){
        this.setState({selectedStartDate: date});
        this.updateData(date, this.state.selectedEndDate);
    };

    handleDateChangeEnd(date){
        this.setState({selectedEndDate: date});
        this.updateData(this.state.selectedStartDate, date);
    };

    render() {
        const { t, loading } = this.props;
        const { showNotRelevant, selectedStartDate, selectedEndDate } = this.state;

        return (
            <div className={'history-page page-wrapper'}>
                <div className='header'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={showNotRelevant}
                                onChange={(event, checked) => this.setState({showNotRelevant: checked})}
                                name="checkedB"
                                color="primary"
                            />
                        }
                        label={t('commands.show-not-relevant')}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className='date-wrapper'>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-start"
                                label="Début"
                                value={selectedStartDate}
                                onChange={this.handleDateChangeStart}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                margin="normal"
                                id="date-picker-end"
                                label="Fin"
                                value={selectedEndDate}
                                onChange={this.handleDateChangeEnd}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
                <Collapse
                    showNotRelevant={showNotRelevant}
                />
                {
                    loading && <div className={'loading-mask'}>
                        <FontAwesomeIcon size={'3x'} className="icon" icon={ faSync } spin/>
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.app.loading,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        findImmosHistory: (startDate, endDate) => (dispatch(findImmosHistory(startDate, endDate)))
    };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(HistoryScreen)));
