import {Component} from "react";
import './Sidebar.scss';
import React from "react";
import Button from "../button/Button";
import List from "../menu/List/List";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

class Sidebar extends Component {
    render() {
        const onClick = this.props.onClick;
        const mainClick = this.props.mainClick;
        return (
            <div className="sidebar">
                <div className="main-button">
                    <span>Dashboard</span>
                </div>
                <List
                    data={this.props.data}
                    onClick={onClick}/>
                <div className="logout-button">
                    <Button text="logout" icon={ faSignOutAlt } onClick={mainClick}/>
                </div>
            </div>
        );
    }
}

export default Sidebar;
