// src/js/store/index.js
import {createStore, applyMiddleware, combineReducers, compose} from "redux";
import rootReducer from "../reducers/index";
import thunk from "redux-thunk";
import {routerMiddleware} from 'react-router-redux'
import {connectRouter} from "connected-react-router";
const createHistory = require("history").createBrowserHistory;

const enhancers = [];
const history = createHistory();
const middleware = [
    thunk,
    routerMiddleware(history)
];
const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    combineReducers({
        app: rootReducer,
        router: connectRouter(history)
    }),
    composedEnhancers
);

export {
    store,
    history
};
