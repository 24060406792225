import React, {Component} from 'react';
import './app.scss';
import AuthService from "./services/authService";
import LoginScreen from "./screens/login/login";
import HomeScreen from "./screens/home/home";
import HistoryScreen from "./screens/history/history";
import AboutScreen from "./screens/about/about";
import Sidebar from './components/sidebar/Sidebar';
import {Route, Redirect, withRouter} from "react-router";
import {endpointAPI} from "./config";
import InterceptorService from "./services/interceptorService";
import i18n from "./i18n";
import Provider from "react-redux/es/components/Provider";
import {I18nextProvider} from "react-i18next";
import {ConnectedRouter} from 'connected-react-router'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import connect from "react-redux/es/connect/connect";
import {faMapMarked, faHistory, faInfo} from "@fortawesome/free-solid-svg-icons";
import {signOut} from "./redux/actions";
import { push } from 'connected-react-router';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axios = require('axios');
const {store, history} = require('./redux/store/index');
const {withTranslation} = require('react-i18next');


// declare private route stack
class PrivateRouteComponent extends Component {
    constructor(props){
        super(props);
        this.listChange = this.listChange.bind(this);
        this.signOut = this.signOut.bind(this);
    }

    listChange(value){
        this.props.push(value);
    }

    signOut(){
        this.props.signOut();
    }
    render() {
        const {t, component: Component, currentLocality,  ...rest} = this.props;
        return (
            <Route {...rest} render={(props) => (
                AuthService.isLogged()
                    ?
                    <div className={'app-wrapper'}>
                        <Sidebar
                            data={
                                [
                                    { "icon":  faMapMarked , "text": "Home", "color": "#FA6B41", path: "/" },
                                    { "icon":  faHistory , "text": "History", "color": "#8e44ad", path: "/history" },
                                    { "icon":  faInfo , "text": "A propos", "color": "#2ecc71", path: "/about" }
                                ]
                            }
                            onClick={this.listChange}
                            mainClick={this.signOut}/>
                        <div className={'screen-wrapper'}>
                            <header>
                                <span>{t('title')}</span>
                                <span>{currentLocality || '-'}</span>
                                <span>{this.props.email}</span>
                            </header>
                            <Component {...props} />
                        </div>
                        <ToastContainer autoClose={1500} position={"bottom-right"}/>
                    </div>
                    :
                    <Redirect to='/login'/>
            )}/>

        );
    }
}

const mapStateToProps = state => {
    return {
        email: state.app.me.email,
        currentLocality: state.app.me.location.locality,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        signOut: () => dispatch(signOut()),
        push: (path) => dispatch(push(path))
    };
}

const PrivateRoute = withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(PrivateRouteComponent)));

// declare public route stack
const NotLoggedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        AuthService.isLogged()
            ? <Redirect to='/'/>
            : <Component {...props} />
    )}/>
);

const theme = createMuiTheme({
    palette: {
        primary: {500: '#295589'},
    },
});

class App extends Component {
    constructor(props) {
        super(props);
        // Initial state
        this.state = {
            unauth: false,
        };

        const me = this;
        // Set axios default base url
        axios.defaults.baseURL = endpointAPI;
        // Init interceptor(axios) & define unauth comportment
        InterceptorService.init(function () {
            // Intercept unauth
            localStorage.clear();
            me.setState({unauth: true})
        });
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <I18nextProvider i18n={i18n}>
                    <Provider store={store}>
                        <div className="app">
                            <ConnectedRouter history={history}>
                                <div>
                                    <NotLoggedRoute exact path="/login" component={LoginScreen}/>
                                    <PrivateRoute exact path="/history" component={HistoryScreen}/>
                                    <PrivateRoute exact path="/about" component={AboutScreen}/>
                                    <PrivateRoute exact path="/" component={HomeScreen}/>
                                </div>
                                {
                                    // If unauth event -> redirect to login screen
                                    this.state.unauth && <Redirect to={'/login'}/>
                                }
                            </ConnectedRouter>
                        </div>
                    </Provider>
                </I18nextProvider>
            </MuiThemeProvider>
        );
    }
}

export default App;
