import React from 'react';
import {GoogleApiWrapper, Map, Marker, Polygon} from 'google-maps-react';
import {connect} from "react-redux";
import {showInfoWindow, hideInfoWindow, updateMyLocation, addSearchPoint} from "../../redux/actions";
import {googleAPIKey} from '../../config';

// Map component
export class MapsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.polygonRef = React.createRef();
        this.map = React.createRef();

        // binding this to event-handler functions
        this.onMessageMarkerClick = this.onMessageMarkerClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onMapReady = this.onMapReady.bind(this);
        this.onEditPolygon = this.onEditPolygon.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.google.maps.event.addListener(this.polygonRef.current.polygon, 'mouseup', this.onEditPolygon);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { lat, lng } = this.props.currentLocation;
        const { polygonPoints } = this.props;
        const isPolygonEqual = this.checkPolygonEquality(polygonPoints, nextProps.polygonPoints);
        return nextProps.currentLocation.lat !== lat
            || nextProps.currentLocation.lng !== lng
            || isPolygonEqual;
    }

    checkPolygonEquality(polygonPoints, nextPolygonPoints) {
        if(polygonPoints.length !== nextPolygonPoints.length)
            return false;

        for(let i = 0; i < polygonPoints.length; i++) {
            const polygonPoint = polygonPoints[i];
            const nextPolygonPoint = nextPolygonPoints[i];
            if(polygonPoint.lat !== nextPolygonPoint.lat
            || polygonPoint.lng !== nextPolygonPoint.lng){
                return false;
            }
        }

        return true;
    }

    /**
     * Fire when a message marker is click on the map
     * @param props
     * @param marker
     */
    onMessageMarkerClick = (props, marker) => {
        // Change state to show infos window of the marker
        this.props.showInfoWindow(marker);
    };

    /**
     * Fire when the map itself is clicked
     * @param props
     * @param map
     * @param event
     */
    onMapClick = (props, map, event) => {
        if (this.props.showingInfoWindow) {
            this.props.hideInfoWindow();
        } else {
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();
            const {locality} = this.props.currentLocation;
            this.props.updateMyLocation(lat, lng, locality);
        }
    };

    onMapReady = (props) => {
        props.google.maps.event.addListener(this.polygonRef.current.polygon, 'mouseup', this.onEditPolygon);
    };

    onEditPolygon = function(event){
        const { vertex, edge, latLng } = event;

        if((!vertex && vertex !== 0) && (!edge && edge !== 0))
            return;

        const lat = latLng.lat();
        const lng = latLng.lng();

        this.props.addSearchPoint(edge, vertex, lat, lng);
    };

    getMapContainerStyle = function(){
        return {
            width: '100vw',
            height: 'calc(100vh - 72px)',
            marginLeft: 'auto',
            marginRight: 'auto'
        };
    };

    render() {
        const { polygonPoints, currentLocation, google } = this.props;

        return (
            <div className={'map-container'}>
                {
                    currentLocation !== null &&
                        <Map
                            zoomControlOptions = {{ position: google.maps.ControlPosition.LEFT_BOTTOM }}
                            streetViewControlOptions = {{ position: google.maps.ControlPosition.LEFT_BOTTOM }}
                            style={this.getMapContainerStyle()}
                            google={google}
                            onClick={this.onMapClick}
                            zoom={17}
                            initialCenter={{lng: currentLocation.lng, lat: currentLocation.lat}}
                            center={{lng: currentLocation.lng, lat: currentLocation.lat}}
                            onReady={this.onMapReady}
                            ref={this.map}>
                            <Polygon
                                ref={this.polygonRef}
                                editable
                                paths={[polygonPoints]}
                                strokeColor="#3c5f8a"
                                strokeOpacity={0.8}
                                strokeWeight={2}
                                fillColor="#3c5f8a"
                                fillOpacity={0.35}
                            />
                            {/* My own position marker */}
                            <Marker position={{lng: currentLocation.lng, lat: currentLocation.lat}}/>
                            {/* Info window with delete button */}
                            {/*
                                this.props.activeMarker &&
                                this.props.activeMarker.value &&
                                <CustomInfoWindow
                                    marker={this.props.activeMarker}
                                    visible={this.props.showingInfoWindow}>
                                    {
                                        <div>
                                            <div>Title: {this.props.activeMarker.value.title}</div>
                                            <div>Interests: {
                                                this.props.activeMarker.value.interests
                                                    .map((interest, index) => <span key={index}>{interest.name + (index === this.props.activeMarker.value.interests.length - 1 ? '' : ', ')}</span>)
                                            }
                                            </div>
                                            <div>Category: {this.props.activeMarker.value.category}</div>
                                            <div>lat: {this.props.activeMarker.position.lat()}</div>
                                            <div>long: {this.props.activeMarker.position.lng()}</div>
                                            {
                                                this.props.activeMarker.value &&
                                                <button onClick={this.onDeleteMessageClick}>Delete</button>
                                            }
                                        </div>
                                    }
                                </CustomInfoWindow>
                            */}
                        </Map>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentLocation: state.app.me.location,
        polygonPoints: state.app.polygonPoints,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        updateMyLocation: (lat, lng, locality) => dispatch(updateMyLocation(lat, lng, locality)),
        showInfoWindow: (marker) => dispatch(showInfoWindow(marker)),
        hideInfoWindow: () => dispatch(hideInfoWindow()),
        addSearchPoint: (edge, vertex, lat, lng) => dispatch(addSearchPoint(edge, vertex, lat, lng)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: googleAPIKey
})(MapsContainer));
