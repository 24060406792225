const axios = require('axios');

class AuthService{

    /**
     * Check if user registration is activated
     */
    static checkUserRegistrationActivated(){
        // Define headers
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        return axios.get('/auth/register', config)
            .then((result => result));
    }

    /**
     *  Sign in a user to the app
     * @param email the email use for auth
     * @param password the password use for auth
     * @param repeatedPassword the repeatPassword use for auth
     * @returns {Promise<T | never>}
     */
    static signUp(email, password, repeatedPassword){
        // Define headers
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        // Define data's
        let data = {
            email,
            username: email,
            password,
            repeatedPassword
        };

        // Send post request
        return axios.post('/auth/register', data, config)
            .then((result) => {
                if(result){
                    localStorage.setItem('token', result.token)
                }
                return result.user;
            });
    }

    /**
     *  Sign in a user to the app
     * @param email the email use for auth
     * @param password the password use for auth
     * @returns {Promise<T | never>}
     */
    static signIn(email, password){
        // Define headers
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        // Define data's
        let data = {
            email,
            password
        };

        // Send post request
        return axios.post('/auth/login', data, config)
            .then((result) => {
                if(result){
                    localStorage.setItem('token', result.token)
                }
                return result.user;
            });
    }

    /**
     *
     * @returns {Promise<number | never>}
     */
    static signOut(){
        return Promise.resolve()
            .then(() => {
                localStorage.removeItem('token');
            });
    }

    /**
     * Check if user is currently logged into the app or not
     * @returns {Promise<boolean | never>} contains the user logged status
     */
    static isLogged(){
        return localStorage.getItem('token') !== null && localStorage.getItem('token') !== 'null';
    }
}

export default AuthService;
