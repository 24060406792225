class DateService {
    static formatTimestamp(timestamp) {
        const date = new Date(timestamp);

        const hours = this.formatClock(date.getHours());
        const minutes = this.formatClock(date.getMinutes());
        return date.toLocaleDateString() + " " + hours + ":" + minutes;
    }

    static timeFromSec(timeInSec){
        const minutes = this.formatClock(Math.floor(timeInSec / 60));
        const seconds = this.formatClock(timeInSec - minutes * 60);
        return minutes + ':' + seconds;
    }

    static formatClock(value){
        return ('' + value).length > 1 ? value : '0' + value;
    }

    static getRangeFromTime(valueInSecond, totalTimeInSecond){
        if(valueInSecond === 0 && totalTimeInSecond === 0){
            return 100;
        }else if(valueInSecond === totalTimeInSecond){
            return 0;
        }
        const outputMin = 0;
        const outputMax = 100;

        const inputMin = 0;
        const inputMax = totalTimeInSecond;

        const percent = (valueInSecond - inputMax) / (inputMin - inputMax);
        return percent * (outputMax - outputMin) + outputMin;
    }
}

export default DateService;
