import {Component} from "react";
import './List.scss';
import React from "react";
import MenuItem from "../menu_item/MenuItem";

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };

        this.onClick = this.onClick.bind(this);
    }

    onClick(index, path){
        this.setState({activeIndex: index});
        this.props.onClick(path);
    }

    render() {
        const { activeIndex } = this.state;
        return (
            <div className="list">
                <div className="inner-list">
                    {
                        this.props.data.map((el, i) =>
                            <MenuItem /*className={activeIndex === i ? 'active' : ''}*/ text={el.text} color={el.color} icon={el.icon} key={i} onClick={(e) => this.onClick(i, el.path)}/>)
                    }
                </div>
            </div>
        );
    }
}

export default List;
