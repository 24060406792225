import {Component} from "react";
import './MenuItem.scss';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class MenuItem extends Component {
    render() {
        const { onClick, text } = this.props;
        return (
            <div className={"menu-item " + this.props.className}>
                <button onClick={onClick}>
                    <FontAwesomeIcon className="icon" icon={ this.props.icon }
                                     style={{ color: this.props.color }}/>
                    <div className="text-wrapper">
                        <span>{ text }</span>
                    </div>
                </button>
            </div>
        );
    }
}

export default MenuItem;
