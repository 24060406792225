const axios = require('axios');

export default {
    init: (redirectLogin) => {
        // Add a request interceptor
        axios.interceptors.request.use(function (config) {
            // Add token to header request
            const token = localStorage.getItem('token');
            if (token != null) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        }, function (err) {
            return Promise.reject(err);
        });

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            return response.data || response;
        }, function (error) {
            //Check if we receive a unauth response status -> logout the user
            if (error.response) {
                const status = parseInt(error.response.status);
                if (status === 401 || status === 403) {
                    redirectLogin();
                }
            }
            return Promise.reject(error);
        });
    }
}
