export const INITIAL_DIST_LAT = 0.0005;
export const INITIAL_DIST_LNG = 0.0007;
export const TIME_SLEEP_REQUESTS_S = 7;
export const RELEVANCY_INDEX = {NONE: -1, RED: 0, ORANGE: 1, GREEN: 2};
export const FR_LOCALITY = [
    'fribourg',
    'fr',
];

export const VD_LOCALITY = [
    'vaud',
    'vd',
];
