import {Component} from "react";
import './login.scss';
import React from "react";
import {withRouter} from "react-router";
import {checkUserRegistrationActivated, signIn, signUp} from "../../redux/actions";
import connect from "react-redux/es/connect/connect";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faSync} from "@fortawesome/free-solid-svg-icons";
import RealExpertLogo from '../../assets/img/realexpert.webp';

const {withTranslation} = require('react-i18next');


// Login screen
class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            repeatedPassword: '',
            step: 0, // 0 = login, 1 = register
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.toggleStep = this.toggleStep.bind(this);

        this.props.checkUserRegistrationActivated();
    }

    /**
     * Handle keypressed form
     * @param event
     */
    handleKeyPress(event) {
        if (event.charCode === 13) {
            this.handleSubmit(event);
        }
    }

    /**
     * Handle submit form
     * @param event
     */
    handleSubmit(event) {
        event.preventDefault();
        const {email, password, repeatedPassword, step} = this.state;

        if (step === 0) {
            this.props.signIn(email, password);
        } else if (step === 1) {
            this.props.signUp(email, password, repeatedPassword);
        }
    }

    /**
     * Handle input update
     * @param event
     */
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    toggleStep() {
        const {step} = this.state;
        this.setState({step: (step + 1) % 2})
    }

    render() {
        const {step} = this.state;
        const {t, loading, error, isUserRegistrationActivated} = this.props;

        return (<div>
                <div className="login-page" id="login-page-wrapper">
                    <div className="corner right"></div>
                    <div className="login-content-wrapper">
                        <div className='logo-wrapper'>
                            <img className='logo' src={RealExpertLogo} alt='realexpert logo'/>
                            <h2>Dashboard RealExpert</h2>
                        </div>
                        <h1>{step === 0 ? t('infos.connection') : t('infos.registration')}</h1>
                        <form className="login-form">
                            <TextField onChange={this.handleChange} onKeyPress={this.handleKeyPress} label="E-mail"
                                       type="email" name="email" id="email"/>
                            <TextField onChange={this.handleChange} onKeyPress={this.handleKeyPress}
                                       className="text-field" label="Mot de passe" type="password" name="password"
                                       id="password"/>
                            {
                                step === 1 &&
                                <TextField onChange={this.handleChange} onKeyPress={this.handleKeyPress}
                                           className="text-field" label="Répéter mot de passe" type="password"
                                           name="repeatedPassword" id="repeatedPassword"/>
                            }
                            <div className='button-loading-outter'>
                                {
                                    loading ?
                                        <FontAwesomeIcon size={'1x'} className="loading-icon" icon={faSync} spin/>
                                        :
                                        <Button className="button" size="medium" variant="contained" color="primary"
                                                onClick={this.handleSubmit}>
                                            {
                                                step === 0 ?
                                                    t('commands.sign-in')
                                                    :
                                                    t('commands.sign-up')
                                            }
                                        </Button>
                                }
                            </div>
                        </form>
                        {
                            isUserRegistrationActivated &&
                            <span className="register-link" onClick={this.toggleStep}>
                            {
                                step === 0 ?
                                    t('commands.sign-up')
                                    :
                                    t('commands.sign-in')
                            }
                        </span>
                        }
                        {
                            error &&
                            <span className="error-txt">{t(this.props.errorText)} </span>
                        }
                    </div>
                    <div className="corner left"></div>
                    <div className="version-number">
                        Dashboard RealExpert 1.0.0
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.app.loading,
        isUserRegistrationActivated: state.app.isUserRegistrationActivated,
        error: state.app.error,
        errorText: state.app.errorText
    };
};

function mapDispatchToProps(dispatch) {
    return {
        checkUserRegistrationActivated: () => dispatch(checkUserRegistrationActivated()),
        signIn: (email, password) => dispatch(signIn(email, password)),
        signUp: (email, password, repeatedPassword) => dispatch(signUp(email, password, repeatedPassword)),
    };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginScreen)));
