// src/js/actions/index.js
import {
    INIT_APP_OK,
    ERROR,
    CLEAR_MAP,
    SIGN_IN_KO,
    SIGN_IN_OK,
    SHOW_INFO_WINDOW,
    HIDE_INFO_WINDOW,
    SIGN_OUT_OK,
    UPDATE_MY_LOCATION,
    ADD_SEARCH_POINT,
    IMMOS_GET,
    IMMOS_GET_OK,
    IMMOS_GET_KO,
    IMMOS_COUNT,
    IMMOS_COUNT_OK,
    IMMOS_COUNT_KO,
    IMMOS_HISTORY_GET,
    IMMOS_HISTORY_GET_OK,
    IMMOS_HISTORY_GET_KO,
    IMMOS_RELEVANCY_KO,
    IMMOS_RELEVANCY_SET,
    IMMOS_RELEVANCY_OK,
    SIGN_IN,
    ADDR_CHANGE,
    ADDR_CHANGE_OK,
    ADDR_CHANGE_KO,
    IMMOS_COMMENT_SET,
    IMMOS_COMMENT_OK,
    IMMOS_COMMENT_KO,
    TIMESTAMP_NAME_SET,
    TIMESTAMP_NAME_OK,
    TIMESTAMP_NAME_KO, CHECK_USER_REGISTRATION_ACTIVATED,
} from "../constants/action-types";
import APIService from "../../services/apiService";
import AuthService from "../../services/authService";
import geoService from "../../services/geoService";
import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import {store} from '../../redux/store/index';
import {VD_LOCALITY} from "../../utils/constants";

export function checkUserRegistrationActivated() {
    return function (dispatch) {
        return AuthService.checkUserRegistrationActivated()
            .then((result) => {
                dispatch({
                    type: CHECK_USER_REGISTRATION_ACTIVATED,
                    payload: result.isRegistrationActivated
                });
            });
    }
}

export function signIn(email, password) {
    return function (dispatch) {
        dispatch({type: SIGN_IN});
        // sign in to app
        return AuthService.signIn(email, password)
            .then(user => {
                if (user) {
                    dispatch({
                        type: SIGN_IN_OK,
                    });
                    dispatch(push('/'));
                }
            })
            .catch(() => {
                dispatch({
                    type: SIGN_IN_KO,
                    payload: {
                        errorText: 'errors.signin-error'
                    }
                });
            });
    }
}

export function signUp(email, password, repeatedPassword) {
    return function (dispatch) {
        dispatch({type: SIGN_IN});
        // sign in to app
        return AuthService.signUp(email, password, repeatedPassword)
            .then(user => {
                if (user) {
                    dispatch({
                        type: SIGN_IN_OK,
                    });
                    dispatch(push('/'));
                }
            })
            .catch((error) => {
                const { status } = error.response;
                switch (status) {
                    case 409:
                        dispatch({
                            type: SIGN_IN_KO,
                            payload: {
                                errorText: 'errors.signup-conflict'
                            }
                        });
                        break;
                    default:
                        dispatch({
                            type: SIGN_IN_KO,
                            payload: {
                                errorText: 'errors.signup-error'
                            }
                        });
                        break;
                }
            });
    }
}

/**
 * Sign out a user from the app
 * @returns {function(*): Promise<number | never | never>}
 */
export function signOut() {
    return function (dispatch) {
        // sign in to app
        return AuthService.signOut()
            .then(() => {
                dispatch({
                    type: SIGN_OUT_OK,
                });
                dispatch(push('/login'));
            });
    }
}

/**
 * Init the application & dispatch result to reducer (fetch initial messages)
 * @returns {function(*): (Q.Promise<any> | * | Promise<T | never>)}
 */
export function initApp() {
    initServices();
    return function (dispatch) {
        return APIService.me()
            .then((me) => {
                // Init app services
                dispatch({
                    type: INIT_APP_OK, payload: me.user.email
                });
            })
            .catch((error) => {
                dispatch({
                    type: ERROR,
                    payload: {
                        error,
                        errorText: 'errors.init-app-error'
                    }
                });
            });
    };
}

/**
 * Init app services
 */
function initServices() {
    // Init geo service
    geoService.init();
}

/**
 * Update my own position on map
 * @returns {Function}
 */
export function updateMyLocation(lat, lng, locality) {
    return function (dispatch) {
        dispatch({
            type: UPDATE_MY_LOCATION,
            payload: {lat, lng, locality}
        });
    };
}

/**
 * Clear all objects in maps & dispatch result to reducer
 * @returns {Function}
 */
export function clearMap() {
    return function (dispatch) {
        dispatch({type: CLEAR_MAP});
    };
}

/**
 * Dispatch to show marker infoWindow
 * @returns {Function}
 */
export function showInfoWindow(marker) {
    return function (dispatch) {
        dispatch({type: SHOW_INFO_WINDOW, payload: marker});
    };
}

/**
 * Dispatch to hide marker infoWindow
 * @returns {Function}
 */
export function hideInfoWindow() {
    return function (dispatch) {
        dispatch({type: HIDE_INFO_WINDOW});
    };
}

export function addSearchPoint(edge, vertex, lat, lng) {
    return function (dispatch) {
        dispatch({
            type: ADD_SEARCH_POINT,
            payload: { edge, vertex, lat, lng }
        });
    }
}

export function findImmos(points, start, offset, locality = store.getState().app.me.location.locality) {
    return function (dispatch) {
        dispatch({type: IMMOS_GET});
        points = VD_LOCALITY.indexOf(locality.toLowerCase()) !== -1 ? [points[3], points[1]] : points;// Bottom left + top right
        return APIService.findImmos(points, start, offset, locality)
            .then((timestamp) => {
                dispatch({
                    type: IMMOS_GET_OK,
                    payload: timestamp
                });
                const receivedDataLength = timestamp.immos.length;
                const wantedDataLength = offset - start;

                if(receivedDataLength === 0 && wantedDataLength > 0){
                    toast.warn("Il semble que vous ayez atteint votre limite journalière")
                }else{
                    toast.success("Données récupérées (" + receivedDataLength + "/" + wantedDataLength + ")");
                }
            })
            .catch((error) => {
                dispatch({type: IMMOS_GET_KO});
                toast.error("Une erreur est survenue");
            });
    }
}

export function countImmos(points, locality = store.getState().app.me.location.locality) {
    return function (dispatch) {
        dispatch({type: IMMOS_COUNT});
        points = VD_LOCALITY.indexOf(locality.toLowerCase()) !== -1 ? [points[3], points[1]] : points;// Bottom left + top right
        return APIService.countImmos(points, locality)
            .then((result) => {
                dispatch({
                    type: IMMOS_COUNT_OK,
                    payload: result
                });
                toast.success("Données récupérées");
            })
            .catch(() => {
                dispatch({type: IMMOS_COUNT_KO});
                toast.error("Une erreur est survenue");
            });
    }
}

export function findImmosHistory(startDate, endDate) {
    return function (dispatch) {
        dispatch({type: IMMOS_HISTORY_GET});
        return APIService.findImmosHistory(startDate, endDate)
            .then((result) => {
                dispatch({
                    type: IMMOS_HISTORY_GET_OK,
                    payload: result
                });
                toast.success("Hitorique récupéré");
            })
            .catch((error) => {
                dispatch({type: IMMOS_HISTORY_GET_KO});
                toast.error("Erreur lors de la récupération de l'historique");
            });
    }
}

export function setImmoRelevancy(immo, isRelevant){
    return function (dispatch) {
        dispatch({type: IMMOS_RELEVANCY_SET});
        return APIService.setImmoRelevancy(immo, isRelevant)
            .then((result) => {
                dispatch({
                    type: IMMOS_RELEVANCY_OK,
                    payload: result
                });
                toast.success(isRelevant ? "Bien pertinent!" : "Bien non pertinent!");
            })
            .catch((error) => {
                dispatch({type: IMMOS_RELEVANCY_KO});
                toast.error("Une erreur est survenue");
            });
    }
}

export function setImmoContactRelevancy(immo, relevancyIndex, contactIndex){
    return function (dispatch) {
        dispatch({type: IMMOS_RELEVANCY_SET});
        return APIService.setImmoContactRelevancy(immo, relevancyIndex, contactIndex)
            .then((result) => {
                dispatch({
                    type: IMMOS_RELEVANCY_OK,
                    payload: result
                });
            })
            .catch((error) => {
                dispatch({type: IMMOS_RELEVANCY_KO});
            });
    }
}

export function setImmoComment(immo, comment){
    return function (dispatch) {
        dispatch({type: IMMOS_COMMENT_SET});
        return APIService.setImmoComment(immo, comment)
            .then((result) => {
                dispatch({
                    type: IMMOS_COMMENT_OK,
                    payload: result
                });
            })
            .catch((error) => {
                dispatch({type: IMMOS_COMMENT_KO});
            });
    }
}

export function setTimestampName(timestamp, name){
    return function (dispatch) {
        dispatch({type: TIMESTAMP_NAME_SET});
        return APIService.setTimestampName(timestamp, name)
            .then((result) => {
                dispatch({
                    type: TIMESTAMP_NAME_OK,
                    payload: result
                });
            })
            .catch((error) => {
                dispatch({type: TIMESTAMP_NAME_KO});
            });
    }
}

export function searchAddr(addr){
    return function (dispatch) {
        dispatch({type: ADDR_CHANGE});
        geoService.fromAddress(addr)
            .then((result) => {
                // Update my location
                const { lat, lng, locality } = result;
                dispatch(updateMyLocation(lat, lng, locality));
                dispatch({type: ADDR_CHANGE_OK, payload: addr});
            })
            .catch((error) => {
                dispatch({type: ADDR_CHANGE_KO});
                // Display error toast
                toast.error("Erreur lors de la recherche");
            });
    }
}
