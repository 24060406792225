import {Component} from "react";
import './Card.scss';
import React from "react";

class Card extends Component {
    render() {
        return (
            <div className={'card ' + this.props.className}>
                {
                    this.props.children
                }
            </div>
        );
    }
}

export default Card;
