import {Component} from "react";
import './about.scss';
import React from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import studio419Logo from '../../assets/img/studio419.ico';
const { withTranslation } = require('react-i18next');

// home screen
class AboutScreen extends Component {
    render() {
        const { t } = this.props;
        return (
            <div className="about-page page-wrapper">
                <h3>{t('infos.title-requests')}</h3>
                <h5>{t('infos.title-requests-first')}</h5>
                <div>{t('infos.requests-first')}</div>
                <h5>{t('infos.title-requests-second')}</h5>
                <div>{t('infos.requests-second_1')}</div>
                <br/>
                <div>{t('infos.requests-second_2')}</div>
                <h5 className='careful'>{t('infos.title-be-careful')}</h5>
                <div>
                    {t('infos.be-careful_1')}
                    <a href='https://www.rf.fr.ch' target='_blank'>www.rf.fr.ch</a>
                    {t('infos.be-careful_2')}
                    <a href='https://www.rf.fr.ch' target='_blank'>https://www.rf.fr.ch/rfpublic/aide_fr.html</a>
                    {t('infos.be-careful_3')}
                    <b>50</b>
                    {t('infos.be-careful_4')}
                    <b>3</b>
                    {t('infos.be-careful_5')}
                    <b>20</b>
                    {t('infos.be-careful_6')}
                </div>
                <h3>{t('infos.title-use')}</h3>
                <div>{t('infos.use')} <b>Realexpert</b></div>
                <h3>{t('infos.title-developed')}</h3>
                <div className='developed'>
                    <img src={studio419Logo}/>
                    <div>
                        <span><a href='https://studio419.ch' target='_blank'><b>Studio419</b></a></span>
                        <span>April, 2020</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default withTranslation('common')(connect(mapStateToProps, mapDispatchToProps)(withRouter(AboutScreen)));
