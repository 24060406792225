import React from 'react';
import './table.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ModalComponent from "../modal/modal";
import {faLink, faTimes, faCheck, faCommentAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "@material-ui/core/Tooltip";
import {setImmoRelevancy, setImmoContactRelevancy, setImmoComment} from "../../redux/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {RELEVANCY_INDEX} from '../../utils/constants';
import TextField from '@material-ui/core/TextField';

class TableComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            openContact: false,
            openComment: false,
            immo: [],
            immoIndex: -1,
            comment: ''
        };

        this.showComments = this.showComments.bind(this);
        this.showPotentialContacts = this.showPotentialContacts.bind(this);
        this.filterDataToShow = this.filterDataToShow.bind(this);
        this.acceptContact = this.acceptContact.bind(this);
        this.acceptComment = this.acceptComment.bind(this);
        this.getContactModalBody = this.getContactModalBody.bind(this);
        this.setImmoRelevancy = this.setImmoRelevancy.bind(this);
        this.setPotentialContactRelevancy = this.setPotentialContactRelevancy.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {immoIndex} = this.state;
        const data = this.filterDataToShow(this.props.data);
        if(immoIndex < 0 || (prevState.immo === data[immoIndex]))
            return null;

        this.setState({
            immo: data[immoIndex]
        });
    }

    getColorByRelevancyIndex(relevancyIndex) {
        switch (relevancyIndex) {
            case RELEVANCY_INDEX.RED:
                return 'red';
            case RELEVANCY_INDEX.ORANGE:
                return 'orange';
            case RELEVANCY_INDEX.GREEN:
                return 'green';
        }
    }

    getContactModalBody() {
        const {immo} = this.state;

        const body = (
            <div className='potential-contacts-outter'>
                {
                    immo && immo.potentialContacts && immo.potentialContacts.length > 0 ?
                        immo.potentialContacts.map((potentialContactInfos, index) => {
                            return <div key={'potential-contact-' + index}>
                                <div className={'header ' + this.getColorByRelevancyIndex(potentialContactInfos.relevancyIndex)}>
                                    <h4>Contact #{index + 1}</h4>
                                    <div className='bubble-outter'>
                                        <div className='bubble red' onClick={() => this.setPotentialContactRelevancy(immo, index, RELEVANCY_INDEX.RED)}>
                                            {
                                                potentialContactInfos.relevancyIndex === RELEVANCY_INDEX.RED
                                                && <div className='border'/>
                                            }
                                        </div>
                                        <div className='bubble orange' onClick={() => this.setPotentialContactRelevancy(immo, index, RELEVANCY_INDEX.ORANGE)}>
                                            {
                                                potentialContactInfos.relevancyIndex === RELEVANCY_INDEX.ORANGE
                                                && <div className='border'/>
                                            }
                                        </div>
                                        <div className='bubble green' onClick={() => this.setPotentialContactRelevancy(immo,index, RELEVANCY_INDEX.GREEN)}>
                                            {
                                                potentialContactInfos.relevancyIndex === RELEVANCY_INDEX.GREEN
                                                && <div className='border'/>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    potentialContactInfos.data.map((info, indexInfo) => {
                                        return <div className={potentialContactInfos.relevancyIndex === RELEVANCY_INDEX.RED ? 'disable' : potentialContactInfos.relevancyIndex === RELEVANCY_INDEX.GREEN ? 'enable' : ''} key={'potential-contact-info-' + indexInfo}>{info}</div>;
                                    })
                                }
                            </div>
                        })
                        :
                        <div className='no-content'>
                            <span>Aucune données de contact</span>
                        </div>
                }
            </div>
        );
        return body;
    }

    /**
     * Handle input update
     * @param event
     */
    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    getCommentModalBody(){
        const {immo} = this.state;

        return <TextField
            onChange={event => this.handleChange(event)}
            name='comment'
            className='text-area'
            id="outlined-multiline-static"
            label="Commentaires"
            multiline
            rows={4}
            defaultValue={immo.comment}
            variant="outlined"
            placeholder="Commentaires..."
        />
    }

    showPotentialContacts(immo, index) {
        this.setState({openContact: true, immo: immo, immoIndex: index});
    }

    showComments(immo, index) {
        this.setState({openComment: true, comment: immo.comment, immo: immo, immoIndex: index});
    }

    acceptContact() {
        this.setState({openContact: false});
    }

    acceptComment(){
        const {comment, immo} = this.state;
        if(comment !== immo.comment) {
            this.props.setImmoComment(immo, comment);
        }
        this.setState({openComment: false});
    }

    setPotentialContactRelevancy(immo, contactIndex, relevancyIndex){
        this.props.setImmoContactRelevancy(immo, relevancyIndex, contactIndex);
    }

    setImmoRelevancy(immo, isRelevant){
        this.props.setImmoRelevancy(immo, isRelevant);
    }

    filterDataToShow(data){
        let {showNotRelevant} = this.props;
        return data
            .filter(immo => immo && immo.building && (!immo.hidden || showNotRelevant));
    }

    render() {
        let {data} = this.props;
        const {openContact, openComment} = this.state;

        data = this.filterDataToShow(data);

        return (
            !data || data.length === 0 ?
                <div className='no-content-small'><span>Aucune donnée</span></div>
                :
                <TableContainer className='table-component' component={Paper}>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>No immeuble</TableCell>
                                {/*<TableCell>No plan</TableCell>*/}
                                <TableCell>Surface&nbsp;(m2)</TableCell>
                                <TableCell>Propriétaire</TableCell>
                                <TableCell align="right">Rue</TableCell>
                                {/*<TableCell align="right">No</TableCell>*/}
                                <TableCell align="right">Type</TableCell>
                                {/*<TableCell align="right">Genre</TableCell>*/}
                                <TableCell align="right">Commentaires</TableCell>
                                <TableCell align="right">Lien</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((immo, index) => (
                                <TableRow key={'immo-' + index}>
                                    <TableCell>{immo.building.noBuilding}</TableCell>
                                    {/*<TableCell>{immo.building.noPlan}</TableCell>*/}
                                    <TableCell>{immo.building.m2 ? immo.building.m2 : '-'}</TableCell>
                                    <TableCell>
                                        <div className='owners-cell'>
                                            {immo.owners.join(', ')}
                                            <IconButton onClick={() => this.showPotentialContacts(immo, index)}>
                                                <FontAwesomeIcon className="icon" icon={ faLink }/>
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                    {
                                        immo.building.flats.length > 0 ?
                                            <React.Fragment>
                                                <TableCell align="right">{immo.building.street.join(', ')}</TableCell>
                                                {/*<TableCell align="right">{immo.building.flats[0].streetNo}</TableCell>*/}
                                                <TableCell
                                                    align="right">{immo.building.flats[0].buildingType}</TableCell>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <TableCell align="right">{immo.building.street}</TableCell>
                                                {/*<TableCell align="right">-</TableCell>*/}
                                                <TableCell align="right">-</TableCell>
                                            </React.Fragment>
                                    }
                                    {/*<TableCell align="right">{immo.building.gender}</TableCell>*/}
                                    <TableCell align="right">
                                        <IconButton onClick={() => this.showComments(immo, index)}>
                                            <FontAwesomeIcon className="icon" icon={ faCommentAlt }/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">
                                        <IconButton href={immo.url} target='_blank'>
                                            <FontAwesomeIcon className="icon" icon={ faLink }/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Tooltip title={immo.hidden ? 'Ajuster ce bien comme pertinent' : 'Ajuster ce bien comme non pertinent'}>
                                            <IconButton onClick={() => this.setImmoRelevancy(immo, immo.hidden)}>
                                                <FontAwesomeIcon color={immo.hidden ? '#74D20E' : '#f03434'} className="icon" icon={ immo.hidden ? faCheck : faTimes }/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <ModalComponent
                        open={openContact}
                        body={this.getContactModalBody()}
                        accept={this.acceptContact}
                    />
                    <ModalComponent
                        open={openComment}
                        body={this.getCommentModalBody()}
                        accept={this.acceptComment}
                    />
                </TableContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setImmoRelevancy: (immo, isRelevant) => dispatch(setImmoRelevancy(immo, isRelevant)),
        setImmoComment: (immo, comment) => dispatch(setImmoComment(immo, comment)),
        setImmoContactRelevancy: (immo, relevancyIndex, contactIndex) => dispatch(setImmoContactRelevancy(immo, relevancyIndex, contactIndex)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableComponent));
