import Geocode from "react-geocode";
import {googleAPIKey} from '../config';

class GEOService{
    static init() {
        Geocode.setApiKey(googleAPIKey);

        // set response language. Defaults to english.
        Geocode.setLanguage("fr");

        // set response region. Its optional.
        // A Geocoding request with region=es (Spain) will return the Spanish city.
        Geocode.setRegion("ch");

        // Enable or disable logs. Its optional.
        // Geocode.enableDebug();
    }

    static fromLatLng(lat, lng) {
        // Get address from latidude & longitude.
        return Geocode.fromLatLng(lat, lng).then(
            response => {
                const address = response.results[0].formatted_address;
                return address;
            }
        );
    }

    static fromAddress(addr) {
        // Get latitude & longitude from address.
        return Geocode.fromAddress(addr).then(
            response => {
                const result = response.results[0];
                const { address_components, geometry } = result;
                const { lat, lng } = geometry.location;

                let locality;
                address_components.forEach((address_component) => {
                    if(address_component.types.indexOf('administrative_area_level_1') !== -1){
                        locality = address_component.long_name;
                    }
                });

                return { lat, lng, locality };
            }
        );
    }
}

export default GEOService;
