import {Component} from "react";
import './Button.scss';
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Button extends Component {
    render() {
        const { onClick, disabled } = this.props;

        return (
            <div className={(disabled ? 'disabled ' : '') + "button"}>
                <button onClick={(event) => {
                    if(!disabled)
                        onClick(event)
                }}>
                    <FontAwesomeIcon className="icon" icon={ this.props.icon } />
                    <div className="text-wrapper">
                        <span>{this.props.text}</span>
                    </div>
                </button>
            </div>
        );
    }
}

export default Button;
